import logo from '../logo.svg';
import hero from '../hero.jpg';
export const Hero = () => {
  const style = {
    backgroundImage: 'url(' + hero + ')',
    minHeight: '100vh',
  };

  return (
    <div
      style={style}
      className="bg-center bg-no-repeat bg-cover relative uppercase xs:bg-scroll sm:bg-scroll md:bg-scroll lg:bg-fixed"
    >
      <div className="bg-black bg-opacity-50 absolute top-0 right-0 bottom-0 left-0">
        <div className="container mx-auto px-8 flex flex-col justify-center items-center h-full text-center">
          <img src={logo} className="max-w-xs" alt="logo" />
          <h1 className="text-white text-4xl font-black mb-3">Dave Mooney</h1>
          <h2 className="text-white text-2xl font-black">
            Frontend Software Engineer based in the UK
          </h2>
          <a
            href="#contactForm"
            className="border-2 border-solid border-white px-4 py-2 rounded-xl mt-4 hover:bg-white hover:text-black transition-all"
          >
            Contact Me
          </a>
        </div>
      </div>
    </div>
  );
};
