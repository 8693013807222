export const Technologies = () => {
  const technologies = [
    { name: 'TypeScript', filename: 'typescript' },
    { name: 'JavaScript', filename: 'javascript' },
    { name: 'React JS', filename: 'react' },
    { name: 'Redux', filename: 'redux' },
    { name: 'Node JS', filename: 'nodejs' },
    { name: 'GraphQL', filename: 'graphql' },
    { name: 'Git', filename: 'git' },
    { name: 'Webpack', filename: 'webpack' },
    { name: 'Next JS', filename: 'next-js' },
    { name: 'MongoDB', filename: 'mongodb' },
    { name: 'CSS3', filename: 'css3' },
    { name: 'HTML5', filename: 'html5' },
    { name: 'SASS', filename: 'sass' },
    { name: 'ESLint', filename: 'eslint' },
    { name: 'Tailwind', filename: 'tailwind' },
  ];
  return (
    <div className="w-full bg-blue-900">
      <div className="container mx-auto px-8 flex flex-col justify-center items-center">
        <h2 className="text-white text-3xl uppercase font-black text-center mt-16 mb-8">
          Tools & Technologies that I Use
        </h2>
        <div
          className="flex flex-wrap justify-around w-full mb-8"
          style={{ maxWidth: 800 }}
        >
          {technologies.map((technology, index) => {
            return (
              <div
                className="flex flex-1 xs:flex-initial flex-col justify-items-center items-center px-4 mb-8"
                key={index}
              >
                <img
                  src={'/icons/' + technology.filename + '.svg'}
                  alt={technology.name}
                  style={{ maxWidth: 50 }}
                />
                <h3 className="mt-2 text-center">{technology.name}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
