export const About = () => {
  const myAge = () => {
    const birthdate = new Date('1989/10/08');
    const today = new Date();
    const diff = today - birthdate; // This is the difference in milliseconds
    const age = Math.floor(diff / 31557600000); // Divide by 1000*60*60*24*365.25
    return age;
  };
  const socialProfiles = [
    {
      name: 'GitHub',
      url: 'https://github.com/daveidivide',
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/davedmooney/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/daveidivide',
    },
  ];
  return (
    <div className="container mx-auto px-8 flex flex-wrap justify-center items-center my-32">
      <div className="max-w-lg text-xl lg:pr-8">
        <p className="mb-4">
          I am a {myAge()} year old software engineer from the UK and I've been
          coding since 2012.
        </p>
        <p className="mb-4">
          The niche I have found myself working in is frontend software
          development.
        </p>
        <p>
          I have used many different tech stacks over the years, but
          my current work is centered around React JS and all the tools that go
          with modern JaveScript software engineering.
        </p>
        <div className="flex items-between mt-4 mb-8">
          {socialProfiles.map((socialProfile, index) => {
            return (
              <a
                href={socialProfile.url}
                target="_blank"
                rel="noreferrer"
                key={index}
                className={index === 0 ? 'mr-4' : 'mx-4'}
              >
                <img
                  src={'/icons/' + socialProfile.name.toLowerCase() + '.svg'}
                  alt={socialProfile.name + ' - Dave Mooney, Software Engineer'}
                  style={{ maxHeight: 40 }}
                />
              </a>
            );
          })}
        </div>
      </div>
      <img
        src="/dave-mooney-software-engineer.jpg"
        alt="Dave Mooney - Software Engineer"
        className="max-w-xs"
      />
    </div>
  );
};
