import './App.css';
import { Hero } from './components/Hero';
import { About } from './components/About';
import { Technologies } from './components/Technologies';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-70856932-1');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <>
      <Hero />
      <About />
      <Technologies />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
