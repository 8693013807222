export const Footer = () => {
  const getCurrentYear = () => {
    const dateObj = new Date();
    const currentYear = dateObj.getFullYear();
    return currentYear;
  };
  return (
    <div className="container mx-auto px-8 mt-32 mb-8">
      <p className="text-center">&#169; Dave Mooney {getCurrentYear()}</p>
    </div>
  );
};
